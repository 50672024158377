/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { adminWhitelist } from '../models/adminWhitelist';
import type { request_adminWhitelist } from '../models/request_adminWhitelist';
import type { request_zoAdmin_cashReceiptImage_journalized } from '../models/request_zoAdmin_cashReceiptImage_journalized';
import type { request_zoAdmin_cashReceiptImages } from '../models/request_zoAdmin_cashReceiptImages';
import type { request_zoAdmin_clients } from '../models/request_zoAdmin_clients';
import type { request_zoAdmin_journals_csvImports } from '../models/request_zoAdmin_journals_csvImports';
import type { request_zoAdmin_partnerTaxAccountantMeetings } from '../models/request_zoAdmin_partnerTaxAccountantMeetings';
import type { request_zoAdmin_partnerTaxAccountants_patch } from '../models/request_zoAdmin_partnerTaxAccountants_patch';
import type { request_zoAdmin_partnerTaxAccountants_post } from '../models/request_zoAdmin_partnerTaxAccountants_post';
import type { request_zoAdmin_partnerTaxAccountantUsers } from '../models/request_zoAdmin_partnerTaxAccountantUsers';
import type { request_zoAdmin_ultra_informations } from '../models/request_zoAdmin_ultra_informations';
import type { request_zoAdmin_ultra_marunageProgress_staffJournalize } from '../models/request_zoAdmin_ultra_marunageProgress_staffJournalize';
import type { request_zoAdmin_ultra_otherJournalRules } from '../models/request_zoAdmin_ultra_otherJournalRules';
import type { request_zoAdmin_ultra_statementFiles_importStatus } from '../models/request_zoAdmin_ultra_statementFiles_importStatus';
import type { request_zoAdmin_ultra_statementJournalRules } from '../models/request_zoAdmin_ultra_statementJournalRules';
import type { request_zoAdmin_ultra_topicMessages } from '../models/request_zoAdmin_ultra_topicMessages';
import type { request_zoAdmin_ultra_topics } from '../models/request_zoAdmin_ultra_topics';
import type { request_zoAdmin_ultra_topics_id_status } from '../models/request_zoAdmin_ultra_topics_id_status';
import type { request_zoAdmin_ultra_usageJournalRules } from '../models/request_zoAdmin_ultra_usageJournalRules';
import type { request_zoAdmin_ultra_users } from '../models/request_zoAdmin_ultra_users';
import type { request_zoAdmin_ultra_userStatus_accounts } from '../models/request_zoAdmin_ultra_userStatus_accounts';
import type { request_zoAdmin_uploadRequiredAccounts } from '../models/request_zoAdmin_uploadRequiredAccounts';
import type { request_zoAdmin_users } from '../models/request_zoAdmin_users';
import type { response_adminWhitelists } from '../models/response_adminWhitelists';
import type { response_topicMessages } from '../models/response_topicMessages';
import type { response_zoAdmin_cashReceiptImage } from '../models/response_zoAdmin_cashReceiptImage';
import type { response_zoAdmin_cashReceiptImages } from '../models/response_zoAdmin_cashReceiptImages';
import type { response_zoAdmin_clients } from '../models/response_zoAdmin_clients';
import type { response_zoAdmin_partnerTaxAccountant } from '../models/response_zoAdmin_partnerTaxAccountant';
import type { response_zoAdmin_partnerTaxAccountants } from '../models/response_zoAdmin_partnerTaxAccountants';
import type { response_zoAdmin_partnerTaxAccountantUsers } from '../models/response_zoAdmin_partnerTaxAccountantUsers';
import type { response_zoAdmin_taxes } from '../models/response_zoAdmin_taxes';
import type { response_zoAdmin_ultra_atTransactionsGroupByRemarks } from '../models/response_zoAdmin_ultra_atTransactionsGroupByRemarks';
import type { response_zoAdmin_ultra_informations } from '../models/response_zoAdmin_ultra_informations';
import type { response_zoAdmin_ultra_marunageProgress_staffJournalize } from '../models/response_zoAdmin_ultra_marunageProgress_staffJournalize';
import type { response_zoAdmin_ultra_otherJournalRules_get } from '../models/response_zoAdmin_ultra_otherJournalRules_get';
import type { response_zoAdmin_ultra_otherJournalRules_post_patch } from '../models/response_zoAdmin_ultra_otherJournalRules_post_patch';
import type { response_zoAdmin_ultra_progresses } from '../models/response_zoAdmin_ultra_progresses';
import type { response_zoAdmin_ultra_reportCategories } from '../models/response_zoAdmin_ultra_reportCategories';
import type { response_zoAdmin_ultra_statementFile } from '../models/response_zoAdmin_ultra_statementFile';
import type { response_zoAdmin_ultra_statementFiles } from '../models/response_zoAdmin_ultra_statementFiles';
import type { response_zoAdmin_ultra_statementJournalRules_get } from '../models/response_zoAdmin_ultra_statementJournalRules_get';
import type { response_zoAdmin_ultra_statementJournalRules_post_patch } from '../models/response_zoAdmin_ultra_statementJournalRules_post_patch';
import type { response_zoAdmin_ultra_topicMessages } from '../models/response_zoAdmin_ultra_topicMessages';
import type { response_zoAdmin_ultra_topics } from '../models/response_zoAdmin_ultra_topics';
import type { response_zoAdmin_ultra_topics_userId } from '../models/response_zoAdmin_ultra_topics_userId';
import type { response_zoAdmin_ultra_usageJournalRules_get } from '../models/response_zoAdmin_ultra_usageJournalRules_get';
import type { response_zoAdmin_ultra_usageJournalRules_post_patch } from '../models/response_zoAdmin_ultra_usageJournalRules_post_patch';
import type { response_zoAdmin_ultra_users } from '../models/response_zoAdmin_ultra_users';
import type { response_zoAdmin_ultra_userStatus_accounts } from '../models/response_zoAdmin_ultra_userStatus_accounts';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class AdminService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Create a new user
     * Creates a new user with specified attributes.
     * @param requestBody
     * @returns any User created successfully
     * @throws ApiError
     */
    public postZoAdminUsers(
        requestBody?: request_zoAdmin_users,
    ): CancelablePromise<{
        message?: string;
    }> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/zo_admin/users',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request - Invalid parameters`,
                401: `Unauthorized - User is not authenticated`,
                500: `Internal Server Error - Unexpected error occurred`,
            },
        });
    }

    /**
     * アクティブULTRAユーザー一覧取得API
     * アクティブULTRAユーザー一覧を取得する。
     * @param year
     * @returns response_zoAdmin_ultra_users Example response
     * @throws ApiError
     */
    public getZoAdminUltraUsers(
        year: number,
    ): CancelablePromise<response_zoAdmin_ultra_users> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/zo_admin/ultra/users',
            query: {
                'year': year,
            },
        });
    }

    /**
     * ULTRAユーザー作成API
     * ULTRAユーザーを作成する。
     * @param requestBody
     * @returns any Created
     * @throws ApiError
     */
    public postZoAdminUltraUsers(
        requestBody?: request_zoAdmin_ultra_users,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/zo_admin/ultra/users',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * ULTRAユーザーステータス取得API
     * ULTRAユーザーステータスを取得する。
     * @param userId
     * @returns response_zoAdmin_ultra_informations Example response
     * @throws ApiError
     */
    public getZoAdminUltraInformationsUserId(
        userId: number,
    ): CancelablePromise<response_zoAdmin_ultra_informations> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/zo_admin/ultra/informations/{user_id}',
            path: {
                'user_id': userId,
            },
        });
    }

    /**
     * ULTRAユーザーステータス更新API
     * ULTRAユーザーステータスを更新する。
     * @param userId
     * @param requestBody
     * @returns response_zoAdmin_ultra_informations Example response
     * @throws ApiError
     */
    public patchZoAdminUltraInformationsUserId(
        userId: number,
        requestBody?: request_zoAdmin_ultra_informations,
    ): CancelablePromise<response_zoAdmin_ultra_informations> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/zo_admin/ultra/informations/{user_id}',
            path: {
                'user_id': userId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * 利用金融機関一覧取得API
     * ユーザーが利用している金融機関の一覧を取得する。
     * @param userId ユーザーID
     * @returns response_zoAdmin_ultra_userStatus_accounts Example response
     * @throws ApiError
     */
    public getZoAdminUltraUserStatusAccountsUserId(
        userId: number,
    ): CancelablePromise<response_zoAdmin_ultra_userStatus_accounts> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/zo_admin/ultra/user_status/accounts/{user_id}',
            path: {
                'user_id': userId,
            },
        });
    }

    /**
     * 利用金融機関更新API
     * 利用金融機関の更新をする。
     * @param userId ユーザーID
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public patchZoAdminUltraUserStatusAccountsUserId(
        userId: number,
        requestBody?: request_zoAdmin_ultra_userStatus_accounts,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/zo_admin/ultra/user_status/accounts/{user_id}',
            path: {
                'user_id': userId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * 仕訳ルール用取引一覧取得API
     * 仕訳ルール用の取引一覧を取得する。
     * @param userId ユーザーID
     * @returns response_zoAdmin_ultra_atTransactionsGroupByRemarks Example response
     * @throws ApiError
     */
    public getZoAdminUltraAtTransactionsGroupByRemarks(
        userId: number,
    ): CancelablePromise<response_zoAdmin_ultra_atTransactionsGroupByRemarks> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/zo_admin/ultra/at_transactions_group_by_remarks',
            query: {
                'user_id': userId,
            },
        });
    }

    /**
     * 明細ごと仕訳ルール一覧取得API
     * 明細ごとの仕訳ルール一覧を取得する。
     * @param userId ユーザーID
     * @param isIncome 収支フラグ
     * @returns response_zoAdmin_ultra_statementJournalRules_get Example response
     * @throws ApiError
     */
    public getZoAdminUltraStatementJournalRules(
        userId: number,
        isIncome?: boolean,
    ): CancelablePromise<response_zoAdmin_ultra_statementJournalRules_get> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/zo_admin/ultra/statement_journal_rules',
            query: {
                'user_id': userId,
                'is_income': isIncome,
            },
        });
    }

    /**
     * 明細ごと仕訳ルール作成API
     * 明細ごとの仕訳ルールを作成する。
     * @param requestBody
     * @returns response_zoAdmin_ultra_statementJournalRules_post_patch Example response
     * @throws ApiError
     */
    public postZoAdminUltraStatementJournalRules(
        requestBody?: request_zoAdmin_ultra_statementJournalRules,
    ): CancelablePromise<response_zoAdmin_ultra_statementJournalRules_post_patch> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/zo_admin/ultra/statement_journal_rules',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * 明細ごと仕訳ルール更新API
     * 明細ごとの仕訳ルールを更新する。
     * @param statementJournalRuleId
     * @param requestBody
     * @returns response_zoAdmin_ultra_statementJournalRules_post_patch Example response
     * @throws ApiError
     */
    public patchZoAdminUltraStatementJournalRulesStatementJournalRuleId(
        statementJournalRuleId: number,
        requestBody?: request_zoAdmin_ultra_statementJournalRules,
    ): CancelablePromise<response_zoAdmin_ultra_statementJournalRules_post_patch> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/zo_admin/ultra/statement_journal_rules/{statement_journal_rule_id}',
            path: {
                'statement_journal_rule_id': statementJournalRuleId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * 明細ごと仕訳ルール削除API
     * 明細ごとの仕訳ルールを削除する。
     * @param statementJournalRuleId
     * @returns void
     * @throws ApiError
     */
    public deleteZoAdminUltraStatementJournalRulesStatementJournalRuleId(
        statementJournalRuleId: number,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/zo_admin/ultra/statement_journal_rules/{statement_journal_rule_id}',
            path: {
                'statement_journal_rule_id': statementJournalRuleId,
            },
        });
    }

    /**
     * 用途ごと仕訳ルール一覧取得API
     * 用途ごとの仕訳ルール一覧を取得する。
     * @param userId ユーザーID
     * @returns response_zoAdmin_ultra_usageJournalRules_get Example response
     * @throws ApiError
     */
    public getZoAdminUltraUsageJournalRules(
        userId: number,
    ): CancelablePromise<response_zoAdmin_ultra_usageJournalRules_get> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/zo_admin/ultra/usage_journal_rules',
            query: {
                'user_id': userId,
            },
        });
    }

    /**
     * 用途ごと仕訳ルール作成API
     * 用途ごとの仕訳ルールを作成する。
     * @param requestBody
     * @returns response_zoAdmin_ultra_usageJournalRules_post_patch Example response
     * @throws ApiError
     */
    public postZoAdminUltraUsageJournalRules(
        requestBody?: request_zoAdmin_ultra_usageJournalRules,
    ): CancelablePromise<response_zoAdmin_ultra_usageJournalRules_post_patch> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/zo_admin/ultra/usage_journal_rules',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * 用途ごと仕訳ルール更新API
     * 用途ごとの仕訳ルールを更新する。
     * @param usageJournalRuleId
     * @param requestBody
     * @returns response_zoAdmin_ultra_usageJournalRules_post_patch Example response
     * @throws ApiError
     */
    public patchZoAdminUltraUsageJournalRulesUsageJournalRuleId(
        usageJournalRuleId: number,
        requestBody?: request_zoAdmin_ultra_usageJournalRules,
    ): CancelablePromise<response_zoAdmin_ultra_usageJournalRules_post_patch> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/zo_admin/ultra/usage_journal_rules/{usage_journal_rule_id}',
            path: {
                'usage_journal_rule_id': usageJournalRuleId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * 用途ごと仕訳ルール削除API
     * 用途ごとの仕訳ルールを削除する。
     * @param usageJournalRuleId
     * @returns void
     * @throws ApiError
     */
    public deleteZoAdminUltraUsageJournalRulesUsageJournalRuleId(
        usageJournalRuleId: number,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/zo_admin/ultra/usage_journal_rules/{usage_journal_rule_id}',
            path: {
                'usage_journal_rule_id': usageJournalRuleId,
            },
        });
    }

    /**
     * その他の仕訳ルール一覧取得API
     * その他の仕訳ルールの一覧を取得する。
     * @param userId ユーザーID
     * @returns response_zoAdmin_ultra_otherJournalRules_get Example response
     * @throws ApiError
     */
    public getZoAdminUltraOtherJournalRules(
        userId: number,
    ): CancelablePromise<response_zoAdmin_ultra_otherJournalRules_get> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/zo_admin/ultra/other_journal_rules',
            query: {
                'user_id': userId,
            },
        });
    }

    /**
     * その他の仕訳ルール作成API
     * その他の仕訳ルールを作成する。
     * @param requestBody
     * @returns response_zoAdmin_ultra_otherJournalRules_post_patch Example response
     * @throws ApiError
     */
    public postZoAdminUltraOtherJournalRules(
        requestBody?: request_zoAdmin_ultra_otherJournalRules,
    ): CancelablePromise<response_zoAdmin_ultra_otherJournalRules_post_patch> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/zo_admin/ultra/other_journal_rules',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * その他の仕訳ルール更新API
     * その他の仕訳ルールを更新する。
     * @param otherJournalRuleId
     * @param requestBody
     * @returns response_zoAdmin_ultra_otherJournalRules_post_patch Example response
     * @throws ApiError
     */
    public patchZoAdminUltraOtherJournalRulesOtherJournalRuleId(
        otherJournalRuleId: number,
        requestBody?: request_zoAdmin_ultra_otherJournalRules,
    ): CancelablePromise<response_zoAdmin_ultra_otherJournalRules_post_patch> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/zo_admin/ultra/other_journal_rules/{other_journal_rule_id}',
            path: {
                'other_journal_rule_id': otherJournalRuleId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * その他の仕訳ルール削除API
     * その他の仕訳ルールを削除する。
     * @param otherJournalRuleId
     * @returns void
     * @throws ApiError
     */
    public deleteZoAdminUltraOtherJournalRulesOtherJournalRuleId(
        otherJournalRuleId: number,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/zo_admin/ultra/other_journal_rules/{other_journal_rule_id}',
            path: {
                'other_journal_rule_id': otherJournalRuleId,
            },
        });
    }

    /**
     * 管理者用 勘定科目取得API
     * 管理者がログインユーザー以外の勘定科目を取得する。
     * @param userId ユーザーID
     * @returns response_zoAdmin_ultra_reportCategories Example response
     * @throws ApiError
     */
    public getZoAdminUltraReportCategories(
        userId: number,
    ): CancelablePromise<response_zoAdmin_ultra_reportCategories> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/zo_admin/ultra/report_categories',
            query: {
                'user_id': userId,
            },
        });
    }

    /**
     * 管理者用 全ユーザーのメッセージ一覧取得API
     * 管理者が全ユーザーのメッセージを取得する。
     * @returns response_zoAdmin_ultra_topics Example response
     * @throws ApiError
     */
    public getZoAdminUltraTopics(): CancelablePromise<response_zoAdmin_ultra_topics> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/zo_admin/ultra/topics',
        });
    }

    /**
     * 管理者用 トピック作成API
     * 管理者がトピックを作成する。
     * @param requestBody
     * @returns any Created
     * @throws ApiError
     */
    public postZoAdminUltraTopics(
        requestBody?: request_zoAdmin_ultra_topics,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/zo_admin/ultra/topics',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * 管理者用 ユーザーのメッセージ一覧取得API
     * 管理者がユーザーのメッセージ一覧を取得する。
     * @param userId ユーザーID
     * @returns response_zoAdmin_ultra_topics_userId Example response
     * @throws ApiError
     */
    public getZoAdminUltraTopicsUserId(
        userId: number,
    ): CancelablePromise<response_zoAdmin_ultra_topics_userId> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/zo_admin/ultra/topics/{user_id}',
            path: {
                'user_id': userId,
            },
        });
    }

    /**
     * 管理者用 メッセージ作成API
     * 管理者がメッセージを作成する。
     * @param requestBody
     * @returns response_zoAdmin_ultra_topicMessages Example response
     * @throws ApiError
     */
    public postZoAdminUltraTopicMessages(
        requestBody?: request_zoAdmin_ultra_topicMessages,
    ): CancelablePromise<response_zoAdmin_ultra_topicMessages> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/zo_admin/ultra/topic_messages',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * 管理者用 トピックステータス更新API
     * 管理者がトピックステータスを更新する。
     * @param id トピックID
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public patchZoAdminUltraTopicsIdStatus(
        id: number,
        requestBody?: request_zoAdmin_ultra_topics_id_status,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/zo_admin/ultra/topics/{id}/status',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * 管理者用 メッセージ一覧取得API
     * 管理者がメッセージ一覧を取得する。
     * @param id トピックID
     * @returns response_topicMessages Example response
     * @throws ApiError
     */
    public getZoAdminUltraTopicsIdMessages(
        id: number,
    ): CancelablePromise<response_topicMessages> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/zo_admin/ultra/topics/{id}/messages',
            path: {
                'id': id,
            },
        });
    }

    /**
     * 管理者用 領収書画像一覧取得API
     * 管理者が領収書画像の一覧を取得する。
     * @param userIds ユーザーIDの配列
     * @param remarks 概要
     * @param fileName
     * @param status all: すべて, backlog: 未仕訳, completed: 仕訳済み
     * @returns response_zoAdmin_cashReceiptImages Example response
     * @throws ApiError
     */
    public getZoAdminCashReceiptImages(
        userIds?: any[],
        remarks?: string,
        fileName?: string,
        status?: 'all' | 'backlog' | 'completed',
    ): CancelablePromise<response_zoAdmin_cashReceiptImages> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/zo_admin/cash_receipt_images',
            query: {
                'user_ids[]': userIds,
                'remarks': remarks,
                'file_name': fileName,
                'status': status,
            },
        });
    }

    /**
     * 管理者用 領収書取得API
     * 管理者が領収書を取得する。
     * @param id 現金の領収書ID
     * @returns response_zoAdmin_cashReceiptImage Example response
     * @throws ApiError
     */
    public getZoAdminCashReceiptImagesId(
        id: number,
    ): CancelablePromise<response_zoAdmin_cashReceiptImage> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/zo_admin/cash_receipt_images/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * 管理者用 領収書更新API
     * 管理者が領収書を更新する。
     * @param id 現金の領収書ID
     * @param requestBody
     * @returns response_zoAdmin_cashReceiptImage Example response
     * @throws ApiError
     */
    public patchZoAdminCashReceiptImagesId(
        id: number,
        requestBody?: request_zoAdmin_cashReceiptImages,
    ): CancelablePromise<response_zoAdmin_cashReceiptImage> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/zo_admin/cash_receipt_images/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * 管理者用 領収書仕訳済みフラグ更新API
     * 管理者が領収書仕訳済みフラグを更新する。
     * @param id 現金の領収書ID
     * @param requestBody
     * @returns any Shared Response
     * @throws ApiError
     */
    public patchZoAdminCashReceiptImagesIdJournalized(
        id: number,
        requestBody?: request_zoAdmin_cashReceiptImage_journalized,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/zo_admin/cash_receipt_images/{id}/journalized',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * 管理者用 明細ファイル一覧取得API
     * 管理者が明細ファイル一覧を取得する。
     * @param userIds ユーザーIDの配列
     * @param hasIncompleted 未処理のファイルが存在するかどうか
     * @returns response_zoAdmin_ultra_statementFiles Example response
     * @throws ApiError
     */
    public getZoAdminUltraStatementFiles(
        userIds?: any[],
        hasIncompleted?: boolean,
    ): CancelablePromise<response_zoAdmin_ultra_statementFiles> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/zo_admin/ultra/statement_files',
            query: {
                'user_ids[]': userIds,
                'has_incompleted': hasIncompleted,
            },
        });
    }

    /**
     * 管理者用 明細ファイル詳細取得API
     * 管理者が明細ファイル詳細を取得する。
     * @param uploadRequierdAccountId アップロードが必要な金融期間ID
     * @returns response_zoAdmin_ultra_statementFile Example response
     * @throws ApiError
     */
    public getZoAdminUltraStatementFilesUploadRequierdAccountId(
        uploadRequierdAccountId: number,
    ): CancelablePromise<response_zoAdmin_ultra_statementFile> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/zo_admin/ultra/statement_files/{upload_requierd_account_id}',
            path: {
                'upload_requierd_account_id': uploadRequierdAccountId,
            },
        });
    }

    /**
     * 管理者用 アップロードされた明細ファイルのインポートステータス更新API
     * 管理者がアップロードされた明細ファイルのインポートステータスを更新する。
     * @param uploadRequiredAccountFileId
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public patchZoAdminUltraStatementFilesUploadRequiredAccountFileIdImportStatus(
        uploadRequiredAccountFileId: string,
        requestBody?: request_zoAdmin_ultra_statementFiles_importStatus,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/zo_admin/ultra/statement_files/{upload_required_account_file_id}/import_status',
            path: {
                'upload_required_account_file_id': uploadRequiredAccountFileId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * 管理者用 仕訳CSVインポートAPI
     * 管理者が仕訳CSVをインポートする。
     * @param formData
     * @returns any Created
     * @throws ApiError
     */
    public postZoAdminJournalsCsvImports(
        formData?: request_zoAdmin_journals_csvImports,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/zo_admin/journals/csv_imports',
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                400: `Example response`,
            },
        });
    }

    /**
     * 管理者用 仕訳CSV削除API
     * 管理者が仕訳CSVを削除する。
     * @param id
     * @returns void
     * @throws ApiError
     */
    public deleteZoAdminJournalsCsvImportsId(
        id: string,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/zo_admin/journals/csv_imports/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * 提携税理士一覧取得API
     * 提携税理士一覧を取得する。
     * @returns response_zoAdmin_partnerTaxAccountants Example response
     * @throws ApiError
     */
    public getZoAdminPartnerTaxAccountants(): CancelablePromise<response_zoAdmin_partnerTaxAccountants> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/zo_admin/partner_tax_accountants',
        });
    }

    /**
     * 提携税理士作成API
     * 提携税理士を作成する。
     * @param requestBody
     * @returns any Created
     * @throws ApiError
     */
    public postZoAdminPartnerTaxAccountants(
        requestBody?: request_zoAdmin_partnerTaxAccountants_post,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/zo_admin/partner_tax_accountants',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * 担当税理士設定API
     * 担当税理士を設定する。
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public patchZoAdminPartnerTaxAccountantUsers(
        requestBody?: request_zoAdmin_partnerTaxAccountantUsers,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/zo_admin/partner_tax_accountant_users',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * 担当税理士情報取得API
     * 担当税理士情報を取得する。
     * @param userId ユーザーID
     * @param year
     * @returns response_zoAdmin_partnerTaxAccountantUsers Example response
     * @throws ApiError
     */
    public getZoAdminPartnerTaxAccountantUsers(
        userId: number,
        year: number,
    ): CancelablePromise<response_zoAdmin_partnerTaxAccountantUsers> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/zo_admin/partner_tax_accountant_users',
            query: {
                'user_id': userId,
                'year': year,
            },
        });
    }

    /**
     * 管理者用 IPアドレス一覧取得API
     * 管理者のIPアドレス一覧を取得する。
     * @returns response_adminWhitelists Example response
     * @throws ApiError
     */
    public getZoAdminAdminWhitelists(): CancelablePromise<response_adminWhitelists> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/zo_admin/admin_whitelists',
        });
    }

    /**
     * 管理者用 IPアドレス作成API
     * 管理者のIPアドレスを作成する。
     * @param requestBody
     * @returns adminWhitelist Example response
     * @throws ApiError
     */
    public postZoAdminAdminWhitelists(
        requestBody?: request_adminWhitelist,
    ): CancelablePromise<adminWhitelist> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/zo_admin/admin_whitelists',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * 管理者用 IPアドレス更新API
     * 管理者のIPアドレスを更新する。
     * @param id
     * @param requestBody
     * @returns adminWhitelist Example response
     * @throws ApiError
     */
    public patchZoAdminAdminWhitelistsId(
        id: number,
        requestBody?: request_adminWhitelist,
    ): CancelablePromise<adminWhitelist> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/zo_admin/admin_whitelists/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * 管理者用 IPアドレス削除API
     * 管理者のIPアドレスを削除する。
     * @param id
     * @returns void
     * @throws ApiError
     */
    public deleteZoAdminAdminWhitelistsId(
        id: number,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/zo_admin/admin_whitelists/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * 管理者用 税区分取得API
     * 管理者が指定されたユーザーの税区分を取得する。
     * @param userId ユーザーID
     * @returns response_zoAdmin_taxes Example response
     * @throws ApiError
     */
    public getZoAdminTaxes(
        userId: number,
    ): CancelablePromise<response_zoAdmin_taxes> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/zo_admin/taxes',
            query: {
                'user_id': userId,
            },
        });
    }

    /**
     * ULTRAユーザー進捗情報取得API
     * ULTRAユーザーの進捗情報を取得する。
     * @param processingYear 会計年度
     * @returns response_zoAdmin_ultra_progresses Example response
     * @throws ApiError
     */
    public getZoAdminUltraProgresses(
        processingYear: number,
    ): CancelablePromise<response_zoAdmin_ultra_progresses> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/zo_admin/ultra/progresses',
            query: {
                'processing_year': processingYear,
            },
        });
    }

    /**
     * 管理者用 取引先一覧取得API
     * 管理者が取引先一覧を取得する。
     * @param userId ユーザーID
     * @returns response_zoAdmin_clients Example response
     * @throws ApiError
     */
    public getZoAdminClients(
        userId: number,
    ): CancelablePromise<response_zoAdmin_clients> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/zo_admin/clients',
            query: {
                'user_id': userId,
            },
        });
    }

    /**
     * 管理者用 取引先作成API
     * 管理者が取引先を作成する。
     * @param requestBody
     * @returns any Created
     * @throws ApiError
     */
    public postZoAdminClients(
        requestBody?: request_zoAdmin_clients,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/zo_admin/clients',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * 管理者用 提携税理士情報取得API
     * 管理者が提携税理士の情報を取得する。
     * @param id
     * @returns response_zoAdmin_partnerTaxAccountant Example response
     * @throws ApiError
     */
    public getZoAdminPartnerTaxAccountantsId(
        id: number,
    ): CancelablePromise<response_zoAdmin_partnerTaxAccountant> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/zo_admin/partner_tax_accountants/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * 管理者用 提携税理士情報更新API
     * 管理者が提携税理士の情報を更新する。
     * @param id
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public patchZoAdminPartnerTaxAccountantsId(
        id: number,
        requestBody?: request_zoAdmin_partnerTaxAccountants_patch,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/zo_admin/partner_tax_accountants/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * 管理者用 提携税理士情報削除API
     * 管理者が提携税理士の情報を削除する。
     * @param id
     * @returns void
     * @throws ApiError
     */
    public deleteZoAdminPartnerTaxAccountantsId(
        id: number,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/zo_admin/partner_tax_accountants/{id}',
            path: {
                'id': id,
            },
            errors: {
                400: `Example response`,
            },
        });
    }

    /**
     * 管理者用 提携税理士ミーティング情報作成API
     * 管理者が提携税理士のミーティング情報を作成する。
     * @param requestBody
     * @returns any Created
     * @throws ApiError
     */
    public postZoAdminPartnerTaxAccountantMeetings(
        requestBody?: request_zoAdmin_partnerTaxAccountantMeetings,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/zo_admin/partner_tax_accountant_meetings',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * 管理者用 提携税理士ミーティング情報更新API
     * 管理者が提携税理士のミーティング情報を更新する。
     * @param id
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public patchZoAdminPartnerTaxAccountantMeetingsId(
        id: number,
        requestBody?: request_zoAdmin_partnerTaxAccountantMeetings,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/zo_admin/partner_tax_accountant_meetings/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * 管理者用 提携税理士ミーティング情報削除API
     * 管理者が提携税理士のミーティング情報を削除する。
     * @param id
     * @returns void
     * @throws ApiError
     */
    public deleteZoAdminPartnerTaxAccountantMeetingsId(
        id: number,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/zo_admin/partner_tax_accountant_meetings/{id}',
            path: {
                'id': id,
            },
            errors: {
                400: `Example response`,
            },
        });
    }

    /**
     * 管理者用 ULTRAユーザー金融機関削除API
     * 管理者がULTRAユーザーの金融機関を削除する。
     * @param id
     * @returns void
     * @throws ApiError
     */
    public deleteZoAdminUltraAccountsId(
        id: number,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/zo_admin/ultra/accounts/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * 管理者用 金融機関情報更新API
     * 管理者が金融機関情報を更新する。
     * @param id
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public patchZoAdminUploadRequiredAccountsId(
        id: number,
        requestBody?: request_zoAdmin_uploadRequiredAccounts,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/zo_admin/upload_required_accounts/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * 管理者用 仕訳完了ステータス取得API
     * 管理者が仕訳完了ステータスを取得する。
     * @param year
     * @param userId ユーザーID
     * @returns response_zoAdmin_ultra_marunageProgress_staffJournalize Example response
     * @throws ApiError
     */
    public getZoAdminUltraMarunageProgressStaffJournalize(
        year: number,
        userId: number,
    ): CancelablePromise<response_zoAdmin_ultra_marunageProgress_staffJournalize> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/zo_admin/ultra/marunage_progress/staff_journalize',
            query: {
                'year': year,
                'user_id': userId,
            },
        });
    }

    /**
     * 管理者用 仕訳完了ステータス更新API
     * 管理者が仕訳完了ステータスを更新する。
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public patchZoAdminUltraMarunageProgressStaffJournalize(
        requestBody?: request_zoAdmin_ultra_marunageProgress_staffJournalize,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/zo_admin/ultra/marunage_progress/staff_journalize',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
