/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type response_taxReturnStatus = {
    review: {
        document_upload_review: response_taxReturnStatus.document_upload_review | null;
        real_estate_properties_review: response_taxReturnStatus.real_estate_properties_review | null;
        tax_accountant_journals_review: response_taxReturnStatus.tax_accountant_journals_review | null;
    };
};

export namespace response_taxReturnStatus {

    export enum document_upload_review {
        BACKLOG = 'backlog',
        COMPLETED = 'completed',
    }

    export enum real_estate_properties_review {
        BACKLOG = 'backlog',
        COMPLETED = 'completed',
    }

    export enum tax_accountant_journals_review {
        BACKLOG = 'backlog',
        COMPLETED = 'completed',
    }


}

