/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type request_zoAdmin_users = {
    name: string;
    email: string;
    stripe_customer_id: string;
    stripe_payment_id: string;
    stripe_subscription_id: string;
    user_type: request_zoAdmin_users.user_type;
    partner_tax_accountant_id: number;
    is_test_user: boolean;
    contract_year: number;
    is_ultra_urgent: boolean;
};

export namespace request_zoAdmin_users {

    export enum user_type {
        NORMAL = 'normal',
        MARUNAGE = 'marunage',
        SEMI_MARUNAGE = 'semi_marunage',
        REAL_ESTATE = 'real_estate',
    }


}

