import Axios, {
  AxiosInstance,
  AxiosResponse,
  AxiosResponseHeaders
} from 'axios'
import * as qs from 'qs'
import {
  AccountItem,
  GetAccountItems
} from 'src/interfaces/api/GetAccountItems'
import { GetAccounts } from 'src/interfaces/api/GetAccounts'
import { GetAdminUsers } from 'src/interfaces/api/GetAdminUsers'
import { GetATLinkWithToken } from 'src/interfaces/api/GetATLinkWithToken'
import { GetClientsResponse } from 'src/interfaces/api/GetClients'
import { GetCompanyResponse } from 'src/interfaces/api/GetCompany'
import { GetInvoiceByIdResponse } from 'src/interfaces/api/GetInvoice'
import {
  GetInvoiceCalculatedAmountsResponse,
  GetInvoiceCaluculatedAmountsPayload
} from 'src/interfaces/api/GetInvoiceCaluclatedAmounts'
import { GetInvoiceDuplicateResponse } from 'src/interfaces/api/GetInvoiceDuplicate'
import { GetInvoiceNumber } from 'src/interfaces/api/GetInvoiceNumber'
import { GetInvoicesResponse } from 'src/interfaces/api/GetInvoices'
import { GetJournals } from 'src/interfaces/api/GetJournals'
import { GetPreDivisionRate } from 'src/interfaces/api/GetPreDivisionRate'
import { GetProcessingYear } from 'src/interfaces/api/GetProcessingYear'
import { GetReport } from 'src/interfaces/api/GetReport'
import { GetReportCategories } from 'src/interfaces/api/GetReportCartegories'
import { GetReportEstimatedIncomeTax } from 'src/interfaces/api/GetReportEstimatedIncomeTax'
import { GetStripeCustomerPortalLink } from 'src/interfaces/api/GetStripeCustomerPortalLink'
import { GetTaxes } from 'src/interfaces/api/GetTaxes'
import { GetUserIsReady } from 'src/interfaces/api/GetUserIsReady'
import { GetUserOnboardingStatus } from 'src/interfaces/api/GetUserOnboardingStatus'
import {
  PatchJournalFromJournals,
  PatchJournalManual
} from 'src/interfaces/api/PatchJournalFromTransaction'
import { PatchTransactions } from 'src/interfaces/api/PatchTransaction'
import { PostAdminUserSignIn } from 'src/interfaces/api/PostAdminUserSignIn'
import { PostAuthEmail } from 'src/interfaces/api/PostAuthEmail'
import {
  PostClientsPayload,
  PostClientsResponse
} from 'src/interfaces/api/PostClients'
import {
  PostImagesSealPayload,
  PostImagesSealResponse
} from 'src/interfaces/api/PostImagesSeal'
import {
  PostInvoicesPayload,
  PostInvoicesResponses
} from 'src/interfaces/api/PostInvoices'
import { PostJournalPayload } from 'src/interfaces/api/PostJournal'
import { CreatedResponse } from '../interfaces/api/CommonResponse'

export default class ApiClient {
  private _axios: AxiosInstance

  constructor(uid?: string, accessToken?: string, client?: string) {
    const headers = {}
    if (uid && accessToken && client) {
      Object.assign(headers, {
        'Content-Type': 'application/json',
        'token-type': 'Bearer',
        uid,
        'access-token': accessToken,
        client
      })
    }

    this._axios = Axios.create({
      baseURL: process.env.NEXT_PUBLIC_APP_API_URL || 'http://localhost:3000',
      headers,
      withCredentials: true
    })
  }

  // Auth ---------------------------------------------------------------
  async postAuthEmail(
    email: string,
    invitation_code?: string,
    utm_source?: string,
    utm_medium?: string,
    utm_campaign?: string,
    utm_content?: string,
    ad_name?: string
  ): Promise<{ headers: AxiosResponseHeaders; data: PostAuthEmail }> {
    const res = await this._axios.post<PostAuthEmail>('/auth/email', {
      email,
      invitation_code,
      utm_source,
      utm_medium,
      utm_campaign,
      utm_content,
      ad_name
    })

    return {
      headers: res.headers,
      data: res.data
    }
  }

  async signIn(email: string, password: string): Promise<AxiosResponse> {
    return await this._axios.post<AxiosResponse>('/auth/sign_in', {
      email,
      password
    })
  }

  async postAuthGoogle(
    uid: string,
    email: string,
    name: string,
    image: string
  ): Promise<{ headers: AxiosResponseHeaders }> {
    const res = await this._axios.post<AxiosResponse>('/auth/google', {
      uid,
      email,
      name,
      image
    })

    return {
      headers: res.headers
    }
  }

  async postConfirmation(
    token: string,
    password: string
  ): Promise<{ headers: AxiosResponseHeaders }> {
    const res = await this._axios.post<AxiosResponse>('/auth/confirmation', {
      confirmation_token: token,
      password
    })
    return { headers: res.headers }
  }

  async postPassword(email: string): Promise<AxiosResponse> {
    return await this._axios.post<AxiosResponse>('/auth/password', {
      email,
      redirect_url: 'https://taxhero.jp'
    })
  }

  async patchPassword(
    token: string,
    password: string
  ): Promise<{ headers: AxiosResponseHeaders }> {
    const res = await this._axios.patch<AxiosResponse>('/auth/password', {
      reset_password_token: token,
      password
    })
    return { headers: res.headers }
  }

  async logout(): Promise<AxiosResponse> {
    const res = await this._axios.delete('/auth/sign_out')
    return res.data
  }

  // Auth Ultra ---------------------------------------------------------------
  async postAuthUltraSignUp(
    email: string,
    utm_source?: string,
    utm_medium?: string,
    utm_campaign?: string,
    utm_content?: string,
    ad_name?: string
  ): Promise<{ headers: AxiosResponseHeaders }> {
    const res = await this._axios.post<CreatedResponse>('/auth/ultra/sign_up', {
      email,
      utm_source,
      utm_medium,
      utm_campaign,
      utm_content,
      ad_name
    })

    return {
      headers: res.headers
    }
  }

  // Admin ---------------------------------------------------------------
  async getAdminUsers(): Promise<GetAdminUsers> {
    const res = await this._axios.get<GetAdminUsers>('/zo_admin/users')
    return res.data
  }

  async postAdminUsers(user_id: number): Promise<{
    headers: AxiosResponseHeaders
    data: PostAdminUserSignIn
  }> {
    const res = await this._axios.post<PostAdminUserSignIn>(
      '/zo_admin/sign_in',
      {
        user_id
      },
      {
        headers: {
          Accept: 'application/json'
        }
      }
    )
    return {
      headers: res.headers,
      data: res.data
    }
  }

  // User Status ---------------------------------------------------------------
  async getUserOnboardingStatus(): Promise<GetUserOnboardingStatus> {
    const res = await this._axios.get<GetUserOnboardingStatus>(
      '/users/onboarding_status'
    )
    return res.data
  }

  // Stripe Customer Portal ---------------------------------------------------------------
  async getStripeCustomerPortal(
    returnUrl: string
  ): Promise<GetStripeCustomerPortalLink> {
    const res = await this._axios.get<GetStripeCustomerPortalLink>(
      `/users/stripe_customer_portal?return_url=${returnUrl}`
    )
    return res.data
  }

  // Accounts ---------------------------------------------------------------
  async getAccounts(): Promise<GetAccounts> {
    const res = await this._axios.get<GetAccounts>('/accounts')
    return res.data
  }

  // AccountItems ---------------------------------------------------------------
  async getAccountItems(): Promise<GetAccountItems> {
    const res = await this._axios.get<GetAccountItems>(`/account_items`)
    return res.data
  }

  async getInvoiceAccountItems(): Promise<GetAccountItems> {
    const res = await this._axios.get<GetAccountItems>(
      `/account_items?scope=invoice`
    )
    return res.data
  }

  async getPrePrivateRate(
    accountItemId: AccountItem['id']
  ): Promise<GetPreDivisionRate> {
    const res = await this._axios.get<GetPreDivisionRate>(
      `/account_items/${accountItemId}/divide`
    )
    return res.data
  }

  // Journals ---------------------------------------------------------------

  async patchJournalEasyMode(
    journalId: number,
    journal: PatchJournalFromJournals
  ): Promise<CreatedResponse> {
    const res = await this._axios.patch<CreatedResponse>(
      `/journals/${journalId}`,
      journal
    )
    return res.data
  }

  async patchJournalProMode(
    journalId: number,
    journal: PatchJournalManual
  ): Promise<CreatedResponse> {
    const res = await this._axios.patch<CreatedResponse>(
      `/journals/${journalId}/manual`,
      journal
    )
    return res.data
  }

  async patchTransactions(payload: PatchTransactions) {
    const res = await this._axios.patch<CreatedResponse>(
      `/at_transactions/`,
      payload
    )
    return res.data
  }

  async getJournals({
    page,
    since,
    until,
    remarks,
    type,
    amount_max,
    amount_min,
    accountIdsQueryString,
    accountItemIdsQueryString
  }: {
    page: string
    since: string
    until: string
    remarks: string
    type: string
    amount_min: string
    amount_max: string
    accountItemIdsQueryString: string
    accountIdsQueryString: string
  }): Promise<GetJournals> {
    const res = await this._axios.get<GetJournals>(
      `/journals?page=${
        page ?? 1
      }&since=${since}&until=${until}&remarks=${remarks}${accountItemIdsQueryString}&type=${type}${accountIdsQueryString}&amount_min=${amount_min}&amount_max=${amount_max}`
    )

    return res.data
  }

  async getReportCategories(): Promise<GetReportCategories> {
    const res = await this._axios.get<GetReportCategories>('/report_categories')
    return res.data
  }

  async postJournal(props: PostJournalPayload): Promise<GetJournals> {
    const res = await this._axios.post<GetJournals>('/journals', props)
    return res.data
  }

  async deleteJournals(journalId: number): Promise<GetJournals> {
    const res = await this._axios.delete<GetJournals>(`/journals/${journalId}`)
    return res.data
  }

  // Report  ---------------------------------------------------------------
  async getReport() {
    const res = await this._axios.get<GetReport>(`/report/pl`)
    return res.data
  }
  async getReportEstimatedIncomeTax() {
    const res = await this._axios.get<GetReportEstimatedIncomeTax>(
      `/report/estimated_income_tax`
    )
    return res.data
  }

  // Account Tracker ---------------------------------------------------------------
  async getAccountTrackerLink(returnUrl: string, accountId?: number | null) {
    const res = await this._axios.get<GetATLinkWithToken>(
      `/at/register?return_url=${returnUrl}${
        accountId ? `&account_id=${accountId}` : ''
      }`
    )
    return res.data
  }

  // User ---------------------------------------------------------------
  // Processing Year ---------------------------------------------------------------
  async getProcessingYear() {
    const res = await this._axios.get<GetProcessingYear>(
      `/users/processing_year`
    )
    return res.data
  }

  async patchProcessingYear(year: number): Promise<CreatedResponse> {
    const res = await this._axios.patch<CreatedResponse>(
      `/users/processing_year`,
      {
        processing_year: year
      }
    )
    return res.data
  }

  // Company ---------------------------------------------------------------
  async getCompany() {
    const res = await this._axios.get<GetCompanyResponse>('/company')
    return res.data
  }

  async deleteCompanySeal() {
    const res = await this._axios.delete('/company/seal_url')
    return res.data
  }

  async deleteCompanyLogo() {
    const res = await this._axios.delete('/company/logo_url')
    return res.data
  }

  // Invoice ---------------------------------------------------------------
  async getInvoices(
    page: number,
    count?: number,
    isDeposited?: boolean,
    client?: string,
    since?: string,
    until?: string,
    amountMin?: string,
    amountMax?: string
  ) {
    const res = await this._axios.get<GetInvoicesResponse>(
      `/invoices?page=${page}${count ? `&count=${count}` : ''}&${
        isDeposited === undefined
          ? client || since || until || amountMin || amountMax
            ? `client=${client}&since=${since}&until=${until}&amount_min=${amountMin}&amount_max=${amountMax}`
            : ''
          : `is_deposited=${isDeposited ? 'true' : 'false'}`
      }`
    )
    return res.data
  }

  async getInvoiceById(id: number) {
    const res = await this._axios.get<GetInvoiceByIdResponse>(`/invoices/${id}`)
    return res.data
  }

  async getDuplicatedInvoiceById(id: number) {
    const res = await this._axios.get<GetInvoiceDuplicateResponse>(
      `/invoices/${id}/duplicate`
    )
    return res.data
  }

  async getInvoiceNumber() {
    const res = await this._axios.get<GetInvoiceNumber>('/invoice/number')
    return res.data
  }

  async getTaxes() {
    const res = await this._axios.get<GetTaxes>('/taxes')
    return res.data
  }

  async getInvoiceCalculatedAmounts(
    props: GetInvoiceCaluculatedAmountsPayload
  ) {
    const res = await this._axios.get<GetInvoiceCalculatedAmountsResponse>(
      '/invoice/calculated_amounts',
      {
        params: props,
        paramsSerializer: (params) => {
          return qs.stringify(params, {
            arrayFormat: 'indices'
          })
        }
      }
    )
    return res.data
  }

  async postInvoice(props: PostInvoicesPayload) {
    const res = await this._axios.post<PostInvoicesResponses>(
      '/invoices',
      props
    )
    return res.data
  }

  async patchInvoice(id: number, props: PostInvoicesPayload) {
    const res = await this._axios.patch<PostInvoicesResponses>(
      `/invoices/${id}`,
      props
    )
    return res.data
  }

  async deleteInvoice(id: number) {
    const res = await this._axios.delete(`/invoices/${id}`)
    return res.data
  }

  // Clients -----------------------------------------------------------
  async postClients(props: PostClientsPayload) {
    const res = await this._axios.post<PostClientsResponse>('/clients', props)
    return res.data
  }

  async getClients() {
    const res = await this._axios.get<GetClientsResponse>('/clients')
    return res.data
  }

  // IsReady ---------------------------------------------------------------
  async getUserIsReady() {
    const res = await this._axios.get<GetUserIsReady>(`/users/is_ready`)
    return res.data
  }
  async patchUserIsReady() {
    const res = await this._axios.patch<CreatedResponse>(`/users/is_ready`)
    return res.data
  }

  // Images ---------------------------------------------------------------
  async postImagesLogo(payload: PostImagesSealPayload) {
    const res = await this._axios.post<PostImagesSealResponse>(
      `/images/logo`,
      payload
    )
    return res.data
  }

  async postImagesSeal(payload: PostImagesSealPayload) {
    const res = await this._axios.post<PostImagesSealResponse>(
      `/images/seal`,
      payload
    )
    return res.data
  }

  // Books ---------------------------------------------------------------
  async getExportBooks() {
    const res = await this._axios.get('/export/books', {
      responseType: 'arraybuffer',
      headers: { Accept: 'application/zip' }
    })
    return res.data
  }

  // Dev ---------------------------------------------------------------
  async postAccountsForDev(): Promise<CreatedResponse> {
    const res = await this._axios.post<CreatedResponse>('/dev/accounts')
    return res.data
  }

  async postDevTaxesUsers() {
    const res = await this._axios.post<GetInvoicesResponse>('/dev/taxes_users')
    return res.data
  }

  async postJournalsForDev(): Promise<GetJournals> {
    const res = await this._axios.post<GetJournals>('/dev/journals')
    return res.data
  }

  async postMockTransactions(): Promise<CreatedResponse> {
    const res = await this._axios.post<CreatedResponse>('/dev/at_transactions')
    return res.data
  }

  async postUltraTasksFinishFirstMeetingTasks(
    withMeetingUrl: boolean
  ): Promise<CreatedResponse> {
    const res = await this._axios.post<CreatedResponse>(
      '/dev/ultra/tasks/finish_first_meeting_tasks',
      { with_meeting_url: withMeetingUrl }
    )
    return res.data
  }
}
