/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { request_taxReturnStatus } from '../models/request_taxReturnStatus';
import type { response_taxReturnStatus } from '../models/response_taxReturnStatus';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class RealEstateService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * 確定申告進捗取得API
     * 確定申告タスクの進捗を取得する。
     * @returns response_taxReturnStatus Example response
     * @throws ApiError
     */
    public getTaxReturnStatus(): CancelablePromise<response_taxReturnStatus> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/tax_return_status',
        });
    }

    /**
     * 確定申告進捗更新API
     * 確定申告タスクの進捗を更新する。
     * @param requestBody
     * @returns response_taxReturnStatus Example response
     * @throws ApiError
     */
    public patchTaxReturnStatus(
        requestBody?: request_taxReturnStatus,
    ): CancelablePromise<response_taxReturnStatus> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/tax_return_status',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * 不動産情報取得API
     * @returns any A list of real estate properties
     * @throws ApiError
     */
    public getRealEstate(): CancelablePromise<Array<{
        id?: number;
        rental_type?: string;
        property_usage?: string;
        location?: string;
        lessee_name?: string;
        lessee_address?: string;
        lease_start_date?: string;
        lease_end_date?: string;
        leased_area?: number;
        monthly_rent?: number;
        annual_rent?: number;
        reward_amount?: number;
        key_money_amount?: number;
        renewal_fee_amount?: number;
        transfer_and_misc_fee?: number;
        security_deposit_balance?: number;
        acquisition_interest_expense?: number;
    }>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/real_estate',
            errors: {
                401: `Unauthorized - Authentication required`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * 不動産情報登録API
     * @param requestBody
     * @returns any Real estate created successfully
     * @throws ApiError
     */
    public postRealEstate(
        requestBody: {
            real_estate_properties?: Array<{
                rental_type?: string;
                property_usage?: string;
                location?: string;
                lessee_name?: string;
                lessee_address?: string;
                lease_start_date?: string;
                lease_end_date?: string;
                leased_area?: number;
                monthly_rent?: number;
                annual_rent?: number;
                reward_amount?: number;
                key_money_amount?: number;
                renewal_fee_amount?: number;
                transfer_and_misc_fee?: number;
                security_deposit_balance?: number;
                acquisition_interest_expense?: number;
            }>;
        },
    ): CancelablePromise<{
        message?: string;
    }> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/real_estate',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request - Missing required parameters`,
                401: `Unauthorized - Authentication required`,
                500: `Internal server error`,
            },
        });
    }

}
