/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type request_taxReturnStatus = {
    category: request_taxReturnStatus.category;
    code: request_taxReturnStatus.code;
    status: request_taxReturnStatus.status;
};

export namespace request_taxReturnStatus {

    export enum category {
        REVIEW = 'review',
    }

    export enum code {
        DOCUMENT_UPLOAD_REVIEW = 'document_upload_review',
        REAL_ESTATE_PROPERTIES_REVIEW = 'real_estate_properties_review',
        TAX_ACCOUNTANT_JOURNALS_REVIEW = 'tax_accountant_journals_review',
    }

    export enum status {
        BACKLOG = 'backlog',
        COMPLETED = 'completed',
    }


}

